import {MINIO_SERVER_URLS, SERVER_URLS} from '@env/baseURL';
import * as packageJson from 'package.json';

const packageVersion = JSON.stringify(packageJson);

export const environment = {
  apiPort: 0,
  apiUrl: SERVER_URLS.pqNet2ApiDev,
  appName: 'ProquifaNet 2',
  appVersion: JSON.parse(packageVersion)?.version,
  environmentServerName: 'dev',
  i18nPrefix: '',
  identityPort: 9001,
  identityUrl: SERVER_URLS.pqNet2IdentityDev,
  production: false,
};

export const minioSettings = {
  accessKey: '7WFRDBJ0CJQ1I69WC9ZW',
  host: MINIO_SERVER_URLS.pqNet2Dev,
  port: 9000,
  secretKey: 'YAHAoUdpPGM7w9Wow4L53M+JWMt8e9pfCNHknHLj',
  useSSL: true,
};
