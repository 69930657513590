// DOCS:Enum que sirve para registrar urls globales del sistema
export enum SERVER_URLS {
  // DOCS: Configuración del servidor (APIS E IDENTITY)
  pqNet2ApiDev = 'https://172.24.32.33',
  pqNet2IdentityDev = 'https://172.24.32.33',

  pqNet2ApiQa = 'https://172.24.32.32',
  pqNet2IdentityQa = 'https://172.24.32.32',

  pqNet2ApiUat = 'https://172.24.20.17',
  pqNet2IdentityUat = 'https://172.24.20.17',

  pqNet2ApiProd = 'https://172.24.20.17',
  pqNet2IdentityProd = 'https://172.24.20.17',

  /*TODO: Revisar config de stock*/
  stock = 'https://172.24.32.33:444',
  stockIdentityPort = 9001,

  stockQA = 'https://172.24.32.32:444',
  stockQAIdentityPort = 9001,

  hostSettingSTOCK = '172.24.20.18',
  portSettingSTOCK = 9000,

  hostQASettingSTOCK = '172.24.32.32',
  portQASettingSTOCK = 9000,
  /* --------------------------- */

  // DOCS: PROMSY
  promsyApiDev = 'https://172.24.32.35',
  promsyIdentityDev = 'https://172.24.32.35',

  promsyApiQa = 'https://172.24.32.32',
  promsyIdentityQa = 'https://172.24.32.32',

  promsyApiUat = 'https://172.24.32.32',
  promsyIdentityUat = 'https://172.24.32.32',

  promsyApiPreProd = 'https://172.24.32.36',
  promsyIdentityPreProd = 'https://172.24.32.36',
}

export enum MINIO_SERVER_URLS {
  pqNet2Dev = '172.24.32.33',
  pqNet2Qa = '172.24.32.32',
  pqNet2Uat = '172.24.20.18',
  pqNet2Prod = '172.24.20.18',
  promsyDev = '172.24.32.35',
  promsyQa = '172.24.32.35',
  promsyPreProd = '172.24.32.36',
}
